import Service, {inject as service} from '@ember/service';
import {action, computed, set} from '@ember/object';
import {storageFor} from 'ember-local-storage';
import {tracked} from '@glimmer/tracking';
import {getOwner} from '@ember/application';

export default class PartnerService extends Service {
  @service ajax;
  @service store;
  @service router;
  @service cookies;
  @service currentUser;

  @tracked showPartnerSelect = true;
  @tracked showPartnerCurrencySelect = true;
  @tracked showCurrentPlayerCurrencies = false;
  @tracked showIsBonusSwitcher = false;
  @tracked optionSystemoptionSystem = false;
  @tracked allCurrencyOption = false;
  @tracked showIsEur = true;
  @tracked rerender = true;
  @tracked selectedId = 0;
  @tracked selectedIdObserverIsSet = false;

  @storageFor('partner') partnerStorage;

  constructor() {
    super(...arguments);
    if (this.currentUser.user.isSystem || this.currentUser.user.isGroupAdmin) {
      this.getAllPartners();
    }
  }

  getAllPartners() {
    return this.store.findAll('partner');
  }

  getPartnersByGroups(groups) {
    return this.store.query('partner', {group: groups})
  }

  get all() {
    return this.store
      .peekAll('partner')
      .slice()
      .sort((a, b) => a.order - b.order);
  }

  get groups() {
    return this.store
      .peekAll('group')
      .slice()
      .sort((a, b) => a.order - b.order);
  }

  get isBonus() {
    return this.partnerStorage.get('isBonus');
  }

  get isEurOn() {
    return this.partnerStorage.get('isEurOn');
  }

  get selected_id() {
    let id = parseInt(this.partnerStorage.get('selected'));

    if (id > 0) {
      return id;
    }

    let partners = this.store.peekAll('partner');
    if (partners.length > 0) {
      return partners[0].id;
    }

    return 0;
  }

  get real_selected_id() {
    return parseInt(this.partnerStorage.get('selected'));
  }

  get selectedPartner() {
    return this.store.peekRecord('partner', parseInt(this.selected_id));
  }

  @computed('all', 'optionSystem', 'router.currentRouteName', 'selected_id')
  get withSystemOption() {
    if (!this.optionSystem && !this.selected_id && this.all[0]) {
      this.setSelected(this.all[0].id);
    }
    return this.optionSystem;
  }

  @computed('all', 'allCurrencyOption', 'currencies', 'currency_id', 'router.currentRouteName')
  get withAllCurrencyOption() {
    if (!this.allCurrencyOption && !this.currency_id && this.all[0]) {
      this.setSelectedCurrency(this.currencies[0]?.id)
    }else if(this.allCurrencyOption){
      this.setSelectedCurrency(0)
    }
    return this.allCurrencyOption;
  }

  getPartnerById(id) {
    return this.store.peekRecord('partner', +id);
  }

  setSelected(val) {
    set(this, 'selectedId', parseInt(val));
    set(this.partnerStorage, 'selected', val);
  }

  setSelectedCurrency(val) {
    set(this.partnerStorage, 'currency', val);
  }

  get currency_id() {
    return this.partnerStorage.get('currency');
  }

  @computed('allCurrencyOption', 'currencies', 'partnerStorage.currency')
  get currency() {
    if (parseInt(this.partnerStorage.get('currency'))) {
      return this.store.peekRecord('currency', parseInt(this.partnerStorage.get('currency')));
    }
    if(this.allCurrencyOption && !this.partnerStorage.get('currency')){
      return null;
    }
    return this.store.peekAll('currency')[0];
  }

  @action
  selectPartner(val) {
    set(this, 'selectedId', parseInt(val));
    set(this.partnerStorage, 'selected', parseInt(val));

    if (this.currencies[0]) {
      set(this.partnerStorage, 'currency', this.currencies[0].get('id'));
    }
    this.reloadPartners()
  }

  @action
  reloadPartners() {
    this.rerender = false;

    setTimeout(() => {
      this.rerender = true;
      getOwner(this).lookup(`route:${this.router.currentRouteName}`).refresh();
    }, 500);
  }

  @action
  isBonusSwitcher() {
    let val = this.partnerStorage.get('isBonus') ? 0 : 1;
    set(this.partnerStorage, 'isBonus', val);
    this.reloadPartners();
  }

  @action
  toggleIsEur(alterVal) {
    let val = typeof alterVal !== 'undefined'?alterVal:(this.partnerStorage.get('isEurOn') ? 0 : 1);
    set(this.partnerStorage, 'isEurOn', val);
    this.reloadPartners();
  }

  get currencies() {
    let selectedPartner = parseInt(this.partnerStorage.get('selected'));
    let partner = this.store.peekRecord('partner', selectedPartner);
    if (!partner) {
      partner = this.store.peekAll('partner').filter((i) => i.id === selectedPartner)[0];
    }
    if (partner || !selectedPartner) {
      let currencies =
        selectedPartner && partner
          ? partner.accounts.map((account) => {
            return account.get('currency');
          })
          : this.store.peekAll('currency');
      if (!this.currency && currencies && currencies[0] && !this.allCurrencyOption) {
        set(this.partnerStorage, 'currency', currencies[0].get('id'));
      }
      return currencies;
    }
    return null;
  }

  @action
  selectCurrency(val) {
    set(this.partnerStorage, 'currency', parseInt(val));
    this.rerender = false;
    setTimeout(() => {
      this.rerender = true;
      getOwner(this).lookup(`route:${this.router.currentRouteName}`).refresh();
      this.router.transitionTo({ queryParams: { page: undefined } })
    }, 500);
  }
}
